define("ember-composable-helpers/helpers/from-entries", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function fromEntries([entries]) {
    if (!entries) {
      return entries;
    }

    return Object.fromEntries(entries);
  });

  _exports.default = _default;
});