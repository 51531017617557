define("ember-svg-jar/inlined/jade-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.67 6.33a1.125 1.125 0 010 1.59L9.795 18.795a1.125 1.125 0 01-1.59 0L3.33 13.92a1.125 1.125 0 011.59-1.59L9 16.41 19.08 6.33a1.125 1.125 0 011.59 0z\" fill=\"#0085A6\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});