define("ember-changeset-validations/validators/exclusion", ["exports", "ember-changeset-validations/utils/validation-errors", "ember-validators/exclusion"], function (_exports, _validationErrors, _exclusion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateExclusion;

  function validateExclusion(options = {}) {
    if (options.list) {
      options.in = options.list;
    }

    return (key, value) => {
      let result = (0, _exclusion.default)(value, options, null, key);
      return result === true ? true : (0, _validationErrors.default)(key, result);
    };
  }
});