define("ember-math-helpers/helpers/log-e", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.logE = logE;
  _exports.default = void 0;

  /**
   * Executes `Math.log` on the number passed to the helper.
   *
   * ```hbs
   * {{log-e a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.log`
   * @return {number} The log of the passed number
   */
  function logE([number]) {
    return Math.log(number);
  }

  var _default = (0, _helper.helper)(logE);

  _exports.default = _default;
});