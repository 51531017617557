define("ember-math-helpers/helpers/mod", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mod = mod;
  _exports.default = void 0;

  /**
   * Takes the modulus of two or more numbers
   *
   * ```hbs
   * {{mod a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to modulus
   * @return {number} The modulus of all the passed numbers
   */
  function mod(numbers) {
    return numbers.reduce((a, b) => Number(a) % Number(b));
  }

  var _default = (0, _helper.helper)(mod);

  _exports.default = _default;
});