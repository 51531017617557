define("ember-svg-jar/inlined/insights-24", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M11.75 3.5a.75.75 0 01.75.75v15.5a.75.75 0 01-1.5 0V4.25a.75.75 0 01.75-.75zm6.5 3.625a.75.75 0 01.75.75V19.75a.75.75 0 01-1.5 0V7.875a.75.75 0 01.75-.75zM5.25 11a.75.75 0 01.75.75v8a.75.75 0 01-1.5 0v-8a.75.75 0 01.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});