define("ember-changeset/helpers/changeset-set", ["exports", "@ember/component/helper", "validated-changeset"], function (_exports, _helper, _validatedChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changesetSet = changesetSet;
  _exports.default = void 0;

  /**
   * This is a drop in replacement for the `mut` helper
   *
   * @method changesetSet
   * @param params
   */
  function changesetSet([obj, path]) {
    if ((0, _validatedChangeset.isChangeset)(obj)) {
      return value => {
        return obj.set(path, value);
      };
    }
  }

  var _default = (0, _helper.helper)(changesetSet);

  _exports.default = _default;
});