define("ember-svg-jar/inlined/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22.666 28v-2.667A5.333 5.333 0 0017.333 20H6.666a5.333 5.333 0 00-5.333 5.333V28M12 14.667A5.333 5.333 0 1012 4a5.333 5.333 0 000 10.667zM30.667 28v-2.667a5.333 5.333 0 00-4-5.16M21.333 4.173a5.334 5.334 0 010 10.333\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});