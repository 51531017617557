define("ember-math-helpers/helpers/acosh", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.acosh = acosh;
  _exports.default = void 0;

  /**
   * Executes `Math.acosh` on the number passed to the helper.
   *
   * ```hbs
   * {{acosh a}}
   * ```
   *
   * @param {number} number The number to pass to `Math.acosh`
   * @return {number} The acosh of the passed number
   */
  function acosh([number]) {
    return Math.acosh(number);
  }

  var _default = (0, _helper.helper)(acosh);

  _exports.default = _default;
});