define("ember-composable-helpers/helpers/filter", ["exports", "@ember/component/helper", "@ember/utils", "ember-composable-helpers/utils/as-array"], function (_exports, _helper, _utils, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filter = filter;
  _exports.default = void 0;

  function filter([callback, array]) {
    if ((0, _utils.isEmpty)(callback) || !array) {
      return [];
    }

    return (0, _asArray.default)(array).filter(callback);
  }

  var _default = (0, _helper.helper)(filter);

  _exports.default = _default;
});