define("ember-composable-helpers/helpers/invoke", ["exports", "@ember/array", "@ember/component/helper", "@ember/utils", "rsvp"], function (_exports, _array, _helper, _utils, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.invoke = invoke;
  _exports.default = void 0;
  const {
    all
  } = _rsvp.default;

  function invoke([methodName, ...args]) {
    let obj = args.pop();

    if ((0, _array.isArray)(obj)) {
      return function () {
        let promises = obj.map(item => (0, _utils.tryInvoke)(item, methodName, args));
        return all(promises);
      };
    }

    return function () {
      return (0, _utils.tryInvoke)(obj, methodName, args);
    };
  }

  var _default = (0, _helper.helper)(invoke);

  _exports.default = _default;
});