define("ember-changeset/helpers/changeset", ["exports", "@ember/component/helper", "ember-changeset", "validated-changeset"], function (_exports, _helper, _emberChangeset, _validatedChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeset = changeset;
  _exports.default = void 0;

  function changeset([obj, validations], options = {}) {
    if (!obj) {
      // route transitions may trigger this
      return;
    }

    if ((0, _validatedChangeset.isChangeset)(obj)) {
      return obj;
    }

    if ((0, _validatedChangeset.isObject)(validations)) {
      if ((0, _validatedChangeset.isPromise)(obj)) {
        return obj.then(resolved => (0, _emberChangeset.Changeset)(resolved, (0, _validatedChangeset.lookupValidator)(validations), validations, options));
      }

      return (0, _emberChangeset.Changeset)(obj, (0, _validatedChangeset.lookupValidator)(validations), validations, options);
    }

    if ((0, _validatedChangeset.isPromise)(obj)) {
      return Promise.resolve(obj).then(resolved => (0, _emberChangeset.Changeset)(resolved, validations, {}, options));
    }

    return (0, _emberChangeset.Changeset)(obj, validations, {}, options);
  }

  var _default = (0, _helper.helper)(changeset);

  _exports.default = _default;
});