define("ember-svg-jar/inlined/bookmark-slash-fill-24", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M3.232 2.175a.75.75 0 10-.964 1.15l2.679 2.244L5 21.253a.75.75 0 001.219.583L12 17.21l5.782 4.625A.75.75 0 0019 21.25v-3.907l1.768 1.482a.75.75 0 10.964-1.15l-18.5-15.5zM7.422 2a.75.75 0 00-.482 1.325l10.828 9.073A.75.75 0 0019 11.823V3.75A1.75 1.75 0 0017.25 2H7.421h.001z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});