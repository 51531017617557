define("ember-changeset-validations/utils/with-defaults", ["exports", "@ember/polyfills"], function (_exports, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = withDefaults;

  /**
   * Create a new object with defaults
   *
   * @public
   * @param  {Object} obj
   * @param  {Object} defaults
   * @return {Object}
   */
  function withDefaults(obj = {}, defaults = {}) {
    return (0, _polyfills.assign)((0, _polyfills.assign)({}, defaults), obj);
  }
});