define("ember-svg-jar/inlined/empora-e-only", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M.037.038H27v3.924H4.05c.22 2.644.874 5.928 2.109 8.785 1.934-1.71 4.405-2.8 7.483-2.8 4.939 0 8.352 2.803 8.339 6.555-.014 3.737-3.426 6.541-8.34 6.541-3.238 0-5.75-1.388-7.664-3.387-1.136 2.513-1.764 5.565-1.953 8.57h22.976v3.925H.037v-1.963c0-4.317.807-9.227 2.842-13.136.17-.328.351-.65.541-.965-.25-.46-.48-.926-.692-1.394C.781 10.4.038 5.342.038 2V.038zm8.147 16.197c1.462 1.83 3.266 2.884 5.457 2.884 3.556 0 4.411-1.782 4.414-2.631.003-.834-.819-2.617-4.414-2.617-2.235 0-4.028.854-5.457 2.364z\" fill=\"#FC4A5A\"/><path d=\"M5.99 19.664c.315-.927 1.476-2.785 2.191-3.423.327.409.54.638 1.08 1.145L7.216 20.77c-.507-.376-.85-.735-1.227-1.106z\" fill=\"url(#paint0_linear_3206_827)\" fill-opacity=\".2\"/><defs><linearGradient id=\"paint0_linear_3206_827\" x1=\"6.934\" y1=\"18.07\" x2=\"8.232\" y2=\"18.981\" gradientUnits=\"userSpaceOnUse\"><stop/><stop offset=\"1\" stop-opacity=\"0\"/></linearGradient></defs>",
    "attrs": {
      "width": "27",
      "height": "33",
      "viewBox": "0 0 27 33",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});